import store from '@/store'
import {qz_localize} from "@/localize/qz";
import {ru_localize} from "@/localize/ru";

export default function localize(key) {
    if (!key) return;
    switch (store.getters["localize/getLocalize"]){
        case 'ru' : return ru_localize.get(key) ? ru_localize.get(key) : key
        case 'qz' : return qz_localize.get(key) ? qz_localize.get(key) : key
    }
}
