<template>
  <v-toolbar-title class="the-brand">
    <img src="../../assets/logo.png" style="height: 32px" alt="logo"/>
  </v-toolbar-title>
</template>

<script>
export default {
  name: "TheBrand"
}
</script>

<style scoped>

</style>
