<template>
  <v-app>
    <the-nav></the-nav>
    <div>
      <the-nav-drawer v-if="isDrawer"/>
      <v-main :class="isDrawer ? 'drawerExist' : ''">
        <router-view></router-view>
      </v-main>
    </div>
    <personal-agreement/>
    <v-snackbar
        v-model="error"
        :color="getError.type ? getError.type : 'white'"
        :timeout="2000"
    >
      <v-icon>{{ getSnackBarIcon }}</v-icon>
      <span style="color: white">
       {{getError.message ? $localize(getError.message) : $localize(errorMessage)}}
      </span>
      <template #action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="closeSnackbar()">
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="getOverlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import {mapGetters} from "vuex";
import PersonalAgreement from "@/components/personalAgreement";
// import TheAccount from "@/components/app/TheAccount";
import TheNav from "@/components/app/TheNav";
import TheNavDrawer from "@/components/app/TheNavDrawer";
import router from "@/router";

export default {
  name: "main-layout",
  components: {/*TheBrand , TheAccount, */ TheNav, TheNavDrawer, PersonalAgreement},
  data: () => ({
    router,
    error: false,
    originalError: {},
    errorMessage: "oops_something_went_wrong_please_try_again_later",
  }),

  computed: {
    ...mapGetters(['getError']),
    ...mapGetters("app", {getOverlay : 'getOverlay'}),

    isDrawer(){
      if(this.$cookies.user){
        const user = JSON.parse(decodeURIComponent(this.$cookies.user))
        if(user.token
            && (router.currentRoute.path.includes('startup') ||
                router.currentRoute.path.includes('investor')||
                (router.currentRoute.path.includes('admin') && user.admin))
        ) return true
      }
      return false
    },

    getSnackBarIcon(){
      switch (this.getError.type){
        case 'success' : return 'mdi-check'
        case 'error' : return 'mdi-close-octagon-outline'
        default : return 'mdi-alert-outline'
      }
    }
  },

  watch: {
    getError(err){
      this.error = err
      this.original_error = err
    },
  },

  methods: {
    toAbout() {
      if (this.$route.name !== 'about') {
        this.$router.push('/about');
      }
    },
    closeSnackbar() {
      this.$store.dispatch("setError", false)
    }
  }
}
</script>

<style scoped lang="scss">
.drawerExist{
  margin-left: 56px;
}
</style>


