// import authMiddleware  from "./middleware/authMiddleware";
import guestMiddleware from "./middleware/guestMiddleware";
import startupMiddleware from "./middleware/startupMiddleware";
import investorMiddleware from "./middleware/investorMiddleware";
import adminMiddleware from "./middleware/adminMiddleware";

const routes = [
    {
        path: '*',
        name: 'error',
        meta: {
            layout: "error",
        },
        component: () => import('../views/Error')
    },
    {
        path: '/',
        redirect: '/home',
        name: 'main',
        meta: {
            layout: "main",
            label: "Главная",
        },
    },
    {
        path: '/home',
        name: 'home',
        meta: {
            layout: "main",
            label: "Главная",
        },
        component: () => import('../views/Home')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/About'),
        meta: {
            layout: 'main',
            label: 'Описание проекта',
        }
    },
    {
        path: '/startup/auth/login',
        name: 'startup-login',
        meta: {
            layout: "main",
            label: "Логин",
            middleware: {
                attach: [guestMiddleware],
            },
        },
        component: () => import('../views/auth/LoginStartup')
    },
    {
        path: '/investor/auth/login',
        name: 'investor-login',
        meta: {
            layout: "main",
            label: "Логин",
            middleware: {
                attach: [guestMiddleware],
            },
        },
        component: () => import('../views/auth/LoginInvestor')
    },
    {
        path: '/admin/auth/login',
        name: 'admin-login',
        meta: {
            layout: "main",
            label: "Логин",
            middleware: {
                attach: [guestMiddleware],
            },
        },
        component: () => import('../views/auth/LoginAdmin')
    },
    {
        path: '/auth/register',
        name: 'register',
        meta: {
            layout: "main",
            label: "Регистрация",
            middleware: {
                attach: [guestMiddleware],
            },
        },
        component: () => import('../views/auth/Register')
    },
    {
        path: '/auth/forgot-password',
        name: 'forgot-password',
        meta: {
            layout: "main",
            label: "Восстановление пароля",
            middleware: {
                attach: [guestMiddleware],
            },
        },
        component: () => import('../views/auth/ForgotPassword')
    },
    {
        path: '/auth/password-reset/:code',
        name: 'reset-password',
        meta: {
            layout: "main",
            label: "Новый пароль",
            middleware: {
                attach: [guestMiddleware],
            },
        },
        component: () => import('../views/auth/ResetPassword')
    },
    //startup
    {
        path: '/startup/profile',
        name: 'startup-profile',
        meta: {
            layout: "main",
            label: "Профиль",
            middleware: {
                attach: [startupMiddleware],
            },

        },
        component: () => import('../views/startup/profile')
    },
    {
        path: '/startup/requests/my',
        name: 'startup-requests',
        meta: {
            layout: "main",
            label: "Заявки",
            middleware: {
                attach: [startupMiddleware],
            }
        },
        component: () => import('../views/startup/requests')
    },
    {
        path: '/startup/request/:uid',
        name: 'startup-request',
        meta: {
            layout: "main",
            label: "Данные по заявке",
            middleware: {
                attach: [startupMiddleware],
            }
        },
        component: () => import('../views/startup/request')
    },
    {
        path: '/startup/dictionaries',
        name: 'startup-dictionaries',
        meta: {
            layout: "main",
            label: "Справочники",
            middleware: {
                attach: [startupMiddleware],
            }
        },
        component: () => import('../views/startup/dictionaries')
    },
    {
        path: '/startup/calculator',
        name: 'startup-calculator',
        meta: {
            layout: "main",
            label: "Калькулятор",
            middleware: {
                attach: [startupMiddleware],
            }
        },
        component: () => import('../views/startup/calculator')
    },

    //ADMIN
    {
        path: '/admin/profile',
        name: 'admin-profile',
        meta: {
            layout: "main",
            label: "Профиль",
            middleware: {
                attach: [adminMiddleware],
            },

        },
        component: () => import('../views/admin/profile')
    },
    {
        path: '/admin/processing-requests',
        name: 'admin-processing-requests',
        meta: {
            layout: "main",
            label: "Входящие заявки",
            middleware: {
                attach: [adminMiddleware],
            }
        },
        component: () => import('../views/admin/processingRequests')
    },
    {
        path: '/admin/processing-payments',
        name: 'admin-processing-payments',
        meta: {
            layout: "main",
            label: "Входящие заявки",
            middleware: {
                attach: [adminMiddleware],
            }
        },
        component: () => import('../views/admin/processingPayments')
    },
    {
        path: '/admin/settings',
        name: 'admin-settings',
        meta: {
            layout: "main",
            label: "Настройки",
            middleware: {
                attach: [adminMiddleware],
            }
        },
        component: () => import('../views/admin/settings')
    },
    {
        path: '/admin/dictionaries',
        name: 'admin-dictionaries',
        meta: {
            layout: "main",
            label: "Настройки",
            middleware: {
                attach: [adminMiddleware],
            }
        },
        component: () => import('../views/admin/dictionaries')
    },
    {
        path: '/admin/processing-request/:uid',
        name: 'processing-request',
        meta: {
            layout: "main",
            label: "Данные по заявке",
            middleware: {
                attach: [adminMiddleware],
            }
        },
        component: () => import('../views/admin/processingRequest')
    },
    {
        path: '/admin/request/credit-rating/:uid',
        name: 'request-credit-rating',
        meta: {
            layout: "main",
            label: "Кредитный рейтинг заёмщика",
            middleware: {
                attach: [adminMiddleware],
            }
        },
        component: () => import('../views/admin/requestsCreditRating')
    },
    {
        path: '/admin/verify/:code',
        name: 'verify-admin',
        meta: {
            layout: "main",
            label: "Подтверждение администратора",
            middleware: {
                attach: [adminMiddleware],
            }
        },
        component: () => import('../views/admin/verifyAdmin')
    },
    //INVESTOR
    {
        path: '/investor/profile',
        name: 'investor-profile',
        meta: {
            layout: "main",
            label: "Профиль",
            middleware: {
                attach: [investorMiddleware],
            },

        },
        component: () => import('../views/investor/Profile')
    },
    {
        path: '/investor/questionnaire',
        name: 'investor-questionnaire',
        meta: {
            layout: "main",
            label: "Опросник",
            middleware: {
                attach: [investorMiddleware],
            },

        },
        component: () => import('../views/investor/Questionnaire')
    },
    {
        path: '/investor/test',
        name: 'investor-test',
        meta: {
            layout: "main",
            label: "Предложения",
            middleware: {
                attach: [investorMiddleware],
            },

        },
        component: () => import('../views/investor/Test')
    },
    {
        path: '/investor/calculator',
        name: 'investor-calculator',
        meta: {
            layout: "main",
            label: "Калькулятор",
            middleware: {
                attach: [investorMiddleware],
            },

        },
        component: () => import('../views/investor/calculator')
    },
];

export default routes;
