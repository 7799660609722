const uuidRegex = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i)
const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const strongPasswordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
const iinRegex = new RegExp(/\d{12,}$/);

export default function testRegex(type, str) {
    switch (type){
        case 'email' : return emailRegex.test(str)
        case 'uid' : return uuidRegex.test(str)
        case 'strongPassword' : return strongPasswordRegex.test(str)
        case 'iin' : return iinRegex.test(str)
    }
}