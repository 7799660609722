export default {
    namespaced: true,
    actions: {
        setShowAgreement(ctx, val){
            ctx.commit('mutateShowAgreement', val)
        },
        setOverlay(ctx, val){
            ctx.commit('mutateOverlay', val)
        },
    },
    mutations: {
        mutateShowAgreement(state, val){
            state.showAgreement = val
        },
        mutateOverlay(state, val){
            state.overlay = val
        },
    },
    getters: {
        getShowAgreement(state){
            return state.showAgreement;
        },
        getOverlay(state){
            return state.overlay;
        },
    },
    state: {
        showAgreement: false,
        overlay: false
    },
}
