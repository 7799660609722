import Cookies from 'js-cookie'

const isLoggedIn = () => !!Cookies.get('user')

const guestMiddleware = async ({ /* to, from to,*/ redirect }) => {
    if (isLoggedIn()) {
        redirect({ name: "main" });
    }
};

export default guestMiddleware;