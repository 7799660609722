import Vue from 'vue'

//js files
import fetch from '@/utils/fetch';
import vm2js from '@/utils/vm2js';
import treeSearch from '@/utils/treeSearch';
import dateFormat from "@/utils/dateFormat";
import dateTimeFormat from "@/utils/dateTimeFormat";
import localize from "@/utils/localize";
import appUtils from "@/utils/appUtils";
import moneyFormat from "@/utils/moneyFormat";
import testRegex from "@/utils/testRegex";
import pmt from "@/utils/pmt";
import enterToTabMixin from "@/utils/enterToTabMixin";
//libraries
import lodash from 'lodash';
import VueReactiveCookie from "vue-reactive-cookie/dist/vue-reactive-cookie";

const utils = {
    install() {
        Vue.prototype.$fetch = fetch
        Vue.prototype.$vm2js = vm2js
        Vue.prototype.$treeSearch = treeSearch
        Vue.prototype.$dateFormat = dateFormat
        Vue.prototype.$dateTimeFormat = dateTimeFormat
        Vue.prototype.$lodash = lodash
        Vue.prototype.$localize = localize
        Vue.prototype.$VueReactiveCookie = VueReactiveCookie
        Vue.prototype.$appUtils = appUtils
        Vue.prototype.$moneyFormat = moneyFormat
        Vue.prototype.$moneyFormat = moneyFormat
        Vue.prototype.$testRegex = testRegex
        Vue.prototype.$pmt = pmt
        Vue.prototype.$enterToTabMixin = enterToTabMixin
    }
}

Vue.use(utils)
