import Cookies from 'js-cookie'

const startupMiddleware = ({ /* to, from to,*/ redirect }) => {
    let user = undefined
    if(Cookies.get('user')) user = JSON.parse(Cookies.get('user'))

    if(!user){
        redirect({
            name: "error",
        });
    }
    else if(user.logAs !== 'startup' || !user.startup){
        redirect({
            name: "error",
        });
    }
};

export default startupMiddleware;