<template>
  <div class="nav-button">
    <v-app-bar app color="#FFF">
      <the-brand class="mr-2"/>
      <v-btn text
             @click="$router.push('/').catch(() => {});"
      >
        {{ $localize('home') }}
      </v-btn>
      <v-btn text
             @click="$router.push('/about').catch(() => {});"
      >
        {{ $localize('about_as') }}
      </v-btn>
      <v-btn v-if="isStartupAuthenticated"
             text
             @click="$router.push('/startup/request/new').catch(() => {});"
      >
        {{ $localize('filing_an_application') }}
      </v-btn>
      <v-spacer></v-spacer>
      <template v-if="show">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                text
                v-bind="attrs"
                v-on="on"
            >
              <v-row class="justify-start align-center">
                <v-icon small class="ml-1 mr-1">mdi-earth</v-icon>
                {{ getLocalize }}
                <!--              <v-icon small>mdi-menu-down</v-icon>-->
              </v-row>
            </v-btn>
          </template>
          <v-list dense class="py-0">
            <v-list-item
                v-for="lang in localizes"
                :key="lang.key"
                class="justify-center"
                style="min-height: 40px"
                @click="setLocalize(lang.key)"
            >
              {{ lang.value }}
            </v-list-item>
          </v-list>
        </v-menu>
        <div v-if="!isAuthenticated">
          <v-btn color="success"
                 outlined
                 @click="$router.push('/auth/register');"
          >
            {{ $localize('registration') }}
          </v-btn>
        </div>
        <div v-else>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  text
                  v-bind="attrs"
                  v-on="on"
              >
                <v-row class="justify-start align-center">
                  <v-icon>mdi-account-circle-outline</v-icon>
                  {{ $localize('profile') }}
                  <v-icon small>mdi-menu-down</v-icon>
                </v-row>
              </v-btn>
            </template>
            <v-list dense class="py-0">
              <v-list-item
                  style="min-height: 40px"
                  class="py-3"
                  @click="enterCabinet"
              >
                {{ $localize('own_cabinet') }}
              </v-list-item>
              <v-divider/>
              <v-list-item
                  style="min-height: 40px"
                  class="justify-center"
                  @click="logout"
              >
                {{ $localize('exit') }}
                <v-icon class="ml-2">mdi-exit-to-app</v-icon>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import TheBrand from "@/components/app/TheBrand";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "the-nav",
  components: {TheBrand},

  data : () => ({
    localizes: [
      {key: 'ru', value: 'RU'},
      {key: 'qz', value: 'QZ'},
    ],
  }),

  methods:{
    ...mapActions('localize', ["setLocalize"]),

    enterCabinet(){
      const user = JSON.parse(decodeURIComponent(this.$cookies.user))
      switch (user.logAs){
        case 'startup' :  this.$router.push('/startup/profile').catch(() => {});
          break
        case 'investor' :  this.$router.push('/investor/profile').catch(() => {});
          break
        case 'admin' :  this.$router.push('/admin/profile').catch(() => {});
          break
      }
    },
    logout(){
      // this.$store.dispatch("logout")
      this.$removeCookie('user');
      this.$router.push('/').catch(() => {});
    },
  },

  computed: {
    ...mapGetters('localize', ['getLocalize']),

    show(){
      const mas = ['login', 'register', 'forgot-password', 'password-reset']
      return !mas.some(r => this.$router.currentRoute.path.includes(r));
    },

    isAuthenticated(){
      let isSigned = false
      if(this.$cookies.user) {
        const user = JSON.parse(decodeURIComponent(this.$cookies.user))
        if(user.token) isSigned = true
      }
      return isSigned
    },
    isStartupAuthenticated(){
      let isStartupSigned = false
      if(this.$cookies.user) {
        const user = JSON.parse(decodeURIComponent(this.$cookies.user))
        if(user.token && user.logAs === 'startup' && !this.$router.currentRoute.path.includes('startup/request/')) isStartupSigned = true
      }
      return isStartupSigned
    },
  }
}
</script>

<style scoped lang="scss">
.nav-button{
  .v-btn{
    margin: 2px;
  }
}
.v-list-item{
  min-height: auto;
}
</style>
