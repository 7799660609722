import * as url from "core-js";
import catchErrors from "@/utils/catchErrors";
import Cookies from 'js-cookie'
// import jwtFilter from "../filters/jwt";
// import storage from "./storage";

const checkForError = response => {
    if (!response.ok) throw response;
    return response;
};

const processingResponse = response => {
    const header = response.headers.get("Content-Type");
    const type = header ? header.split(";")[0] : null;
    switch (type) {
        case "application/json":
            return response.json();
        case "text/plain":
            return response.text();
        case "application/doc":
            return new Promise(resolve => {
                response.blob().then(res => {
                    resolve({
                        filename: header.split(";")[1].split("=")[1],
                        blob: res
                    })
                });
            })
        case "application/pdf":
            return new Promise(resolve => {
                response.blob().then(res => {
                    resolve({
                        filename: header.split(";")[1].split("=")[1],
                        blob: res
                    })
                });
            })
        case "application/octet-stream":
            return new Promise(resolve => {
                response.blob().then(res => {
                    resolve({
                        filename: header.split(";")[1].split("=")[1],
                        blob: res
                    })
                });
            })
        default:
            return true;
    }
};

const processingCatch = error => {
    return catchErrors(error)
};

async function Get(path, params) {
    const apiUrl = process.env.VUE_APP_BACK_END_URL
    let user = undefined
    if(Cookies.get('user')) {
        user = JSON.parse(Cookies.get('user'))
    }
    let request = {
        method: "GET",
        mode: "cors",
        credentials: 'include',
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: user ?  "Bearer " + user.token : undefined,
            Username: user ? user.username : undefined
        }
    };
    if (params && typeof(params) !== 'object' && params.length === undefined)
        return console.log("Error: URL params is not object. Please get Object an a property.");
    const urlProp = params ? "?" + new url.URLSearchParams(params) : undefined;
    return await fetch(urlProp ? `${apiUrl}${path}${urlProp}` : `${apiUrl}${path}`, request)
        .then(checkForError)
        .then(processingResponse)
        .catch(processingCatch);
}

async function Post(path, payload) {
    const apiUrl = process.env.VUE_APP_BACK_END_URL
    let user = undefined
    if(Cookies.get('user')) {
        user = JSON.parse(Cookies.get('user'))
    }
    return await fetch(`${apiUrl}${path}`, {
        method: "POST",
        mode: "cors",
        credentials: 'include',
        body:
            payload === null
                ? null
                : typeof payload === "object"
                ? JSON.stringify(payload)
                : payload,
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: user ?  "Bearer " + user.token : undefined,
            Username: user ? user.username : undefined
        }
    })
        .then(checkForError)
        .then(processingResponse)
        .catch(processingCatch);
}

async function GetFile(path, params) {
    const apiUrl = process.env.VUE_APP_BACK_END_URL
    let user = undefined
    if(Cookies.get('user')) {
        user = JSON.parse(Cookies.get('user'))
    }
    let request = {
        method: "GET",
        mode: "cors",
        credentials: 'include',
        headers: {
            Authorization: user ?  "Bearer " + user.token : undefined,
            Username: user ? user.username : undefined
        }
    };
    // if (params && typeof(params) !== 'object' && params.length === undefined)
    //     return console.log("Error: URL params is not object. Please get Object an a property.");
    const urlProp = params ? "?" + new url.URLSearchParams(params) : undefined;
    return await fetch(urlProp ? `${apiUrl}${path}${urlProp}` : `${apiUrl}${path}`, request)
        .then(checkForError)
        .then(processingResponse)
        .catch(processingCatch);
}

async function PostFile(path, file) {
    const apiUrl = process.env.VUE_APP_BACK_END_URL
    let user = undefined
    if(Cookies.get('user')) {
        user = JSON.parse(Cookies.get('user'))
    }
    let formData = new FormData();
    formData.append('file', file)

    return await fetch(`${apiUrl}${path}`, {
        method: "POST",
        mode: "cors",
        credentials: 'include',
        body: formData,
        headers: {
            Authorization: user ?  "Bearer " + user.token : undefined,
            Username: user ? user.username : undefined
        }
    })
        .then(checkForError)
        .then(processingResponse)
        .catch(processingCatch);
}

export default {
    get: async (path, params = undefined) => {
        return await Get(path, params);
    },
    post: async (path, payload) => {
        return await Post(path, payload);
    },
    postFile: async (path, file) => {
        return await PostFile(path, file);
    },
    getFile: async (path, file) => {
        return await GetFile(path, file);
    }
};
