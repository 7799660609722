export const ru_localize = new Map ([
    //Aa
    ["about_as", "О нас"],
    ["ACTIVITY", "Деятельность"],
    ["add", "Добавить"],
    ["add_dictionary", "Добавить справочник"],
    ["already_have_an_account?", "Уже есть аккаунт?"],
    ["amount", "Сумма"],
    ["analysis_date", "Дата анализа"],
    ["application_information", "Информация по заявке"],
    ["are_you_sure_you_want_to_confirm_payment", "Вы уверены что хотите подтвердить оплату?"],
    ["are_you_sure_you_want_to_delete", "Вы уверены что хотите удалить?"],
    ["are_you_sure_you_want_to_restore", "Вы уверены что хотите восстановить?"],
    ["ASSETS", "Активы"],
    ["attach_a_receipt", "Прикрепите чек"],
    ["actions", "Действия"],
    ["attention_the_balance_does_not_converge", "Проверьте данные («Итого активы» должно быть равно «Итого пассивы»)"],
    ["assets_total", "Итого активы"],
    ["application_not_processed", "Заявка не обработана"],

    //Bb
    ["back", "Назад"],
    ["balance", "Баланс"],
    ["bank", "Банк"],
    ["become_an_investor", "Стать инвестором"],
    ["before_work_agree_with_agreement", "Перед началом работы ознакомьтесь с пользовательским соглашением"],
    ["bin_of_the_company", "БИН компании"],
    ["borrower_credit_rating", "Кредитный рейтинг заёмщика"],
    ["BOTH", "Пополнение и приобретение основных средств"],

    //Cc
    ["calculate", "Расчитать"],
    ["calculator", "Калькулятор"],
    ["cancel", "Отмена"],
    ["CAPITAL", "Капитал"],
    ["cashless_turnover_in_the_company_tenge", "Безналичный оборот в компании, тенге"],
    ["cell_phone_of_the_first_head", "Сотовый телефон первого руководителя"],
    ["check_number", "№ чека"],
    ["check_s_file", "Файл чека"],
    ["choose_a_bank", "Выберите банк"],
    ["choose_the_indicator", "Выберите показатель"],
    ["choose_the_period", "Выберите период"],
    ["COMMITMENTS", "Обязательства"],
    ["company_name", "Имя компании"],
    ["company_type", "Тип компании"],
    ["confirm", "Подтвердить"],
    ["confirm_profile", "Подтвердить"],
    ["confirm?", "Подтвердить"],
    ["confirm_payment", "Подтвердить оплату"],
    ["confirmation_history", "История подтверждений"],
    ["confirmed", "Подтверждено"],
    ["CREATE", "Создана"],
    ["create_request", "Создать заявку"],
    ["credit_sum", "Сумма кредита"],
    ["criteria_signs_assessment_of_group_and_intragroup_indicators", "Критерии/признаки/оценки групповых и внутригрупповых показателей"],
    ["city_of_residence", "Город проживания"],
    ["close", "Закрыть"],
    ["create_an_application", "Сформировать заявку"],

    //Dd
    ["data_sending_error", "Ошибка отправки данных"],
    ["delete", "Удалить"],
    ["deleted", "Удалено"],
    ["detailed_information", "Подробная информация"],
    ["details", "Подробнее"],
    ["dictionaries", "Справочники"],
    ["DRAFT", "Создание заявки"],

    //Ee
    ["edit", "Редактировать"],
    ["edit_dictionary", "Редактировать справочник"],
    ["edit_period", "Редактировать период"],
    ["eighted_score", "Взвешененая оценка"],
    ["eights", "Веса"],
    ["email", "Эл. почта"],
    ["email_of_the_first_manager", "Email первого руководителя"],
    ["enter_a_check_number", "Введите номер чека"],
    ["enter_iin", "Введите ИИН"],
    ["enter_bin", "Введите БИН"],
    ["enter_email", "Введите эл. почту"],
    ["enter_initial_data", "Введите вводные данные..."],
    ["enter_payment_amount", "Введите сумму оплаты"],
    ["enter_payment_limit", "Введите лимит оплаты"],
    ["error_try_again_later", "Ошибка, повторите позднее"],
    ["exit", "Выйти"],
    ["EXPENSES", "Расходы"],

    //Ff
    ["field_of_activity", "Сфера деятельности"],
    ["fill_all_fields_correctly", "Заполните правильно все поля"],
    ["fill_out_the_forms", "Заполните формы!"],
    ["fill_out_the_questionnaire", "Заполните опросник"],
    ["fill_the_form", "Заполните анкету"],
    ["fill_the_necessary_fields", "Заполните обязаткельные поля"],
    ["filing_an_application", "Подать заявку"],
    ["financial_condition_of_the_borrower", "Финансовое состояние заёмщика"],
    ["fio", "ФИО"],
    ["first_name", "Имя"],
    ["forget_password?", "Забыли пароль?"],
    ["founder_email", "Email учредителя"],
    ["founder_s_phone", "Сотовый телефон учредителя"],
    ["full_company_name", "Полное наименование компании"],
    ["full_name_of_the_first_head", "ФИО первого руководителя"],
    ["full_name_of_the_founder_s", "ФИО Учредителя/ей"],
    ["financial_statements", "Финансовая отчетность"],

    //Gg
    //Hh
    ["home", "Главная"],

    //Ii
    ["IE", "Индивидуальный предприниматель"],
    ["iin_of_the_first_head", "ИИН первого руководителя"],
    ["iin_of_the_founder", "ИИН учредителя"],
    ["INCOME", "Доходы"],
    ["incoming_requests_for_confirmation_of_payment", "Входящие заявки на подтверждение оплаты"],
    ["incoming_requests_for_processing", "Входящие заявки на обработку"],
    ["indicator_name", "Наименование показателя"],
    ["indicators", "Показатели"],
    ["investment_purpose", "Цель инвестирования"],

    //Jj
    //Kk
    //Ll
    ["last_name", "Фамилия"],
    ["letter_was_sent_to_email", "Письмо было отправлено на почтовый ящик"],
    ["limit", "Лимит"],
    ["LLP", "Юридическое лицо"],
    ["login", "Вход"],
    ["loading", "Загрузка..."],

    //Mm
    ["mandatory_field", "Обязательное поле"],
    ["middle_name", "Отчество"],
    ["models", "Модели"],
    ["models_settings", "Настройка моделей"],
    ["monthly", "Месячно"],
    ["my_requests", "Мои заявки"],

    //Nn
    ["new_password_set", "Новоый пароль сохранен"],
    ["next", "Далее"],
    ["no", "нет"],
    ["no_confirmed", "Не подтверждено"],
    ["nomer_p_p", "№ п/п"],
    ["no_data", "Данные отсутствуют"],

    //Oo
    ["other", "Другое"],
    ["own_cabinet", "Личный кабинет"],
    ["oops_something_went_wrong_please_try_again_later.", "Личный кабинет"],

    //Pp
    ["PASSIVE", "Пассивы"],
    ["passive_total", "Итого пассивы"],
    ["password", "Пароль"],
    ["password_recovery", "Восстановления пароля"],
    ["password_should_be_not_less_than_8_symbols_and_contains_a_digit_and_letter_in_upper_and_lower_case", "Пароль должен быть не менее 8 символов, содержать цифру, букву в нижнем, в верхнем регистре"],
    ["payment_date", "Дата оплаты"],
    ["payment_limit", "Лимит оплаты"],
    ["payments_processing", "Проверка оплаты по заявке"],
    ["per_cent", "Процентная ставка"],
    ["period", "Срок"],
    ["period_month", "Срок месяцев"],
    ["period_year", "Срок лет"],
    ["photo", "Фотография"],
    ["planned_amount_of_your_investment_in_one_project", "Планируемая сумма Ваших инвестиций в один проект (в тенге)"],
    ["planned_investment_period", "Планируемый срок инвестирования"],
    ["predefault_rating", "Преддефолтный рейтинг"],
    ["PROCESS", "Заявка на рассмотрении"],
    ["processing_completed", "Обработка завершена!"],
    ["profile", "Профиль"],
    ["PROFIT", "Прибыль и убытки"],
    ["proof_of_payment", "Подтверждение оплаты"],
    ["providing_a_personal_guarantee_to_the_founder_s", "Предоставление личной гарантии учредителя/ей"],
    ["PURCHASE", "Приобретение основных средств"],
    ["payment_history", "История оплат"],
    ["profit_total", "Итого прибыль и убытки"],

    //Qq
    ["quality_rating", "Качественный рейтинг"],
    ["quantity_rating", "Количественный рейтинг"],
    ["quarterly", "Квартально"],
    ["questionnaire", "Опросник"],

    //Rr
    ["reduced_period", "Льготный период"],
    ["register_successfully", "Регистрация прошла успешно!"],
    ["registration", "Регистрация"],
    ["repeat_password", "Повторите пароль"],
    ["REPLENISHMENT", "Пополнение оборотных средств"],
    ["request_sent", "Заявка отправлена!"],
    ["requested_amount_tenge", "Запрашиваемая сумма, тенге"],
    ["RequestProcessingChesser", "Модель Чессера"],
    ["RequestProcessingIgea", "Модель ИГЭА"],
    ["RequestProcessingTaffler", "Модель Таффлера"],
    ["requests", "Заявки"],
    ["requests_processing", "Рассмотрение заявок"],
    ["required_reference_books_are_missing", "Отсутствуют необходимые справочники"],
    ["restore", "Восстановить"],
    ["risk_assessment_class", "Класс оценки риска"],

    //Ss
    ["save", "Сохранить"],
    ["save_as_draft", "Сохранить как черновик"],
    ["saved", "Сохранено"],
    ["save_new_password", "Сохранить новый пароль"],
    ["save_as_draft", "Сохранить как черновик"],
    ["send", "Отправить"],
    ["send_a_request", "Отправить заявку!"],
    ["send_a_request_for_verify_account", "Отправить запрос на подтверждение аккаунта?"],
    ["settings", "Настройки"],
    ["sign_in", "Войти"],
    ["sing_in_like_admin", "Кабинет администратора"],
    ["sing_in_like_investor", "Стать инвестором"],
    ["sing_in_like_startup", "Получить инвестиции"],
    ["status", "Статус"],
    ["success", "Успешно"],
    ["section_total", "Итого по разделу"],

    //Tt
    ["telephone_number", "Номер телефона"],
    ["the_list_of_applications_is_empty", "Список заявок пуст..."],
    ["the_purpose_of_attracting", "Цель привлечения"],
    ["type", "Тип"],
    ["there_is_nothing_here_yet", "Здесь пока ничего нет..."],

    //Uu
    //Vv
    ["value_of_indicators", "Значение показателей"],
    ["view", "Просмотр"],

    //Ww
    ["what_is_the_accounting", "В чем ведется учет"],
    ["why_did_you_decide_to_invest_in_ABC_deals?", "Почему Вы решили инвестировать в сделки ABC?"],
    ["work_experience_of_the_first_head_months", "Стаж работы первого руководителя, месяцев"],
    ["work_experience_of_the_founder_s__max_months", "Стаж работы учредителя/лей, месяцев (макс)"],
    ["wrong_login_or_password", "Неверный логин или пароль"],

    //Xx
    //Yy
    ["yearly", "По годам"],
    ["yes", "да"],

    //Zz
])
