<template>
  <div>
    <br>
    <h4>ОБЯЗАТЕЛЬСТВО О НЕРАЗГЛАШЕНИИ КОНФИДЕНЦИАЛЬНОЙ ИНФОРМАЦИИ</h4>
    <br>
    Настоящим «_____________»________, именуемое в дальнейшем «Клиент», в лице____, действующего на основании____ в рамках Договора №_____ от _____________ (далее - Договор) предоставляет настоящее Обязательство о неразглашении конфиденциальной информации, заключающееся в нижеследующем:<br>
    1.  Клиент принимает на себя обязательство по неразглашению Информации, составляющей коммерческую тайну<sup>1</sup>, и Конфиденциальной информации<sup>2</sup>, в соответствии с условиями настоящего Обязательства.<br>
    2.  Клиент обязуется не передавать ABC Информацию по незащищенным каналам связи (с использованием факсимильной связи, сетей Интернет, Интранет) без принятия мер, обеспечивающих ее защиту.<br>
    3.  Клиент обязуется использовать Информацию, полученную от ABC строго в целях, необходимых для исполнения Договора. При этом не осуществлять без предварительного письменного согласия ABC раскрытие информации, ее обмен, опубликование либо разглашение иным способом, в том числе посредством ксерокопирования, воспроизведения или с использованием электронных носителей любым третьим лицам.<br>
    4.  Клиент не будет иметь обязательств по данному Обязательству в отношении Информации, полученной от ABC, и Информация не будет считаться конфиденциальной, если:<br>
    ✓   была известна третьим лицам до даты получения Информации от ABC;<br>
    ✓   стала достоянием общественности на дату её раскрытия не по вине Клиента;<br>
    ✓   должна быть раскрыта в соответствии с действующим законодательством, или по распоряжению, указу, постановлению или предписанию государственных органов.<br>
    5.  Клиент может раскрывать Информацию своим сотрудникам, должностным лицам, руководителям без предварительного письменного согласия ABC следующим лицам, с обязательным обеспечением соблюдения конфиденциальности в отношении такой Информации.<br>
    6.  Клиент обязуется незамедлительно информировать ABC о случаях разглашения Информации, организовать расследование этих фактов.<br>
    7.  Права и обязанности Клиента по настоящему Обязательству в случае реорганизации переходят к соответствующему правопреемнику (правопреемникам). В случае своей ликвидации Клиент должен до завершения ликвидации обеспечить возврат Информации на любых носителях ABC.<br>
    8.  Клиент несет ответственность перед Передающей стороной в соответствии с законодательством Республики Казахстан в случае нарушения условий настоящего Обязательства, в том числе любым лицом, которому эта информация была передана в рамках исполнения Договора.<br>
    9.  В случае нарушения Клиентом своих обязательств ABC вправе принимать меры, направленные на защиту своих прав в соответствии с законодательством Республики Казахстан.<br>
    10. Все споры и разногласия, которые могут возникнуть между Клиентом и ABC в связи с настоящим Обязательством, будут по возможности разрешаться путем переговоров. При невозможности урегулирования споров путем переговоров в разумные сроки такие споры, передаются для окончательного разрешения в судебные органы Республики Казахстан в соответствии с процессуальным законодательством.<br>
    11. Настоящее Обязательство толкуется и регулируется в соответствии с законодательством Республики Казахстан.<br>
    12. Настоящее Обязательство вступает в силу с даты его подписания Клиентом и действует до исполнения Договора, а в части сохранения конфиденциальности информации в течение пяти лет после окончания срока действия Договора.<br>
    13. Настоящее Обязательство подписано в двух экземплярах, имеющих одинаковую юридическую силу, один экземпляр Клиенту, один экземпляр ABC.<br>
    <br><v-divider/><br>
    <sup>1</sup>сведения любого характера о способах осуществления профессиональной деятельности, которые имеют действительную или потенциальную коммерческую ценность в силу неизвестности ее третьим лицам, к которым у третьих лиц нет свободного доступа на законном основании ABC;<br>
    <sup>2</sup>финансовая, экономическая, производственная и любая иная информация, имеющая отношение к деятельности ABC, ставшая известной Клиенту при получении услуг ABC.<br>
    <br>
  </div>
</template>

<script>
export default {
  name: "AgreementText"
}
</script>

<style scoped>

</style>
