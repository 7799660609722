<template>
  <v-dialog
      v-model = "agreement.show"
      width="1000"
      persistent
      scrollable
  >
    <v-card v-if="agreement.logAs !== 'admin'">
      <v-card-title>
        {{ $localize('before_work_agree_with_agreement') }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text id="agreement-text">
        <InvestorAgreementText v-if="agreement.logAs === 'investor'"/>
        <StartupAgreementText v-else-if="agreement.logAs === 'startup'"/>
        <v-divider></v-divider>
        <v-card-actions style="margin-bottom: -20px">
          <template v-if="!getShowAgreement">
            <v-checkbox v-model="isAgree" label="Я принимаю условия пользовательского соглашения и политику конфиденциальности"></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn
                :disabled="!enable"
                color="primary"
                text
                @click="send"
            >
              {{ $localize('send') }}
            </v-btn>
          </template>
          <template v-else>
            <v-spacer></v-spacer>
            <v-btn color="primary"
                   text
                   @click="setShowAgreement(false)"
            > OK </v-btn>
          </template>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title class="justify-center">
        {{ $localize('send_a_request_for_verify_account') }}
      </v-card-title>
      <v-card-actions class="justify-center">
        <v-btn text color="grey" :disabled="!emailIsSending" @click="logout">{{$localize('exit')}}</v-btn>
        <v-btn color="success"
               :disabled="emailIsSending"
               @click="sendConfirmForAdmin"
        > {{ $localize('send') }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InvestorAgreementText from './InvestorAgreementText'
import StartupAgreementText from "./StartupAgreementText";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "personalAgreement",
  components: {InvestorAgreementText, StartupAgreementText},

  data: () => ({
    isAgree: false,
    isFetching: false,
    emailIsSending: false,
  }),

  computed: {
    ...mapGetters('app', ['getShowAgreement']),

    enable(){
      return this.isAgree && !this.isFetching
    },

    agreement(){
      let agreement = {show: false,  logAs: undefined}
      if(this.$cookies.user){
        const user = JSON.parse(decodeURIComponent(this.$cookies.user))
        agreement.logAs = user.logAs
        if(this.getShowAgreement) agreement.show = true
        else{
          if(user) {
            switch (user.logAs){
              case 'startup' : agreement.show = !user.startup
                break
              case 'investor' : agreement.show = !user.investor
                break
              case 'admin' : agreement.show = !user.admin
                break
            }
          }
        }
      }
      return agreement
    }
  },

  watch:{
    'agreement.show'(value){
      if(!value) {
        this.isAgree = false
        let aText = document.getElementById('agreement-text');
        if(aText) aText.scrollTop = 0;
      }
    }
  },

  methods: {
    ...mapActions('app', ['setShowAgreement']),

    async send() {
      if(this.$cookies.user){
        let user = JSON.parse(decodeURIComponent(this.$cookies.user))
        switch (this.agreement.logAs) {
          case 'investor' : {
            this.isFetching = true
            let res = await this.$fetch.post('api/agreement/investor/sign');
            this.isFetching = false
            if (res && res.status === 'ACCESS') user.investor = true
            this.$setCookie('user', user, { expires: 1 });
            break
          }
          case 'startup' : {
            this.isFetching = true
            let res = await this.$fetch.post('api/agreement/startup/sign');
            this.isFetching = false
            if (res && res.status === 'ACCESS') user.startup = true
            this.$setCookie('user', user, { expires: 1 });
            break
          }
        }
      }
    },

    async sendConfirmForAdmin(){
      this.emailIsSending = true
      const res = await this.$fetch.post('api/auth/send-confirm-for-admin?url=' + location.origin);
      if(res && res.status === 'ACCESS') this.$store.dispatch('setError',{ show: true, type: 'success', message: this.$localize('request_sent')})
    },

    logout(){
      // this.$store.dispatch("logout")
      this.$removeCookie('user');
      this.$router.push('/').catch(() => {});
    },
  },
}
</script>
