import Vue from 'vue'
import App from './App.vue'
import vuetify from "./plugins/vuetify"
import store from "./store"
import router from "./router"
import "./plugins/utils"
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);
import VueReactiveCookie from 'vue-reactive-cookie'
import DatetimePicker from 'vuetify-datetime-picker'
//money format
import money from 'v-money'
import VuetifyMoney from "vuetify-money"


Vue.config.productionTip = false
Vue.use(VueReactiveCookie);
Vue.use(DatetimePicker);
//money format
Vue.use(money);
Vue.use(VuetifyMoney); //work is not correct


new Vue({
  vuetify,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
