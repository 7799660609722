export default {
    namespaced: true,
    actions: {
        setLocalize(ctx, localize){
            ctx.commit('mutateLocalize', localize)
        },
    },
    mutations: {
        mutateLocalize(state, localize){
           if(state.localize !== localize) state.localize = localize
        },
    },
    getters: {
        getLocalize(state){
            return state.localize;
        },
    },
    state: {
        localize: 'ru',
    },
}
