export const qz_localize = new Map ([
    //Aa
    ["about_as", "О нас QZ"],
    ["ACTIVITY", "Деятельность QZ"],
    ["add", "Добавить QZ"],
    ["add_dictionary", "Добавить справочник QZ"],
    ["already_have_an_account?", "Уже есть аккаунт? QZ"],
    ["amount", "Сумма QZ"],
    ["analysis_date", "Дата анализа QZ"],
    ["application_information", "Информация по заявке QZ"],
    ["are_you_sure_you_want_to_confirm_payment", "Вы уверены что хотите подтвердить оплату? QZ"],
    ["are_you_sure_you_want_to_delete", "Вы уверены что хотите удалить? QZ"],
    ["are_you_sure_you_want_to_restore", "Вы уверены что хотите восстановить? QZ"],
    ["ASSETS", "Активы QZ"],
    ["attach_a_receipt", "Прикрепите чек QZ"],

    //Bb
    ["back", "Назад QZ"],
    ["balance", "Баланс QZ"],
    ["bank", "Банк QZ"],
    ["become_an_investor", "Стать инвестором QZ"],
    ["before_work_agree_with_agreement", "Перед началом работы ознакомьтесь с пользовательским соглашением QZ"],
    ["bin_of_the_company", "БИН компании QZ"],
    ["borrower_credit_rating", "Кредитный рейтинг заёмщика QZ"],
    ["BOTH", "Пополнение и приобретение основных средств QZ"],

    //Cc
    ["calculator", "Калькулятор QZ"],
    ["cancel", "Отмена QZ"],
    ["CAPITAL", "Капитал QZ"],
    ["cashless_turnover_in_the_company_tenge", "Безналичный оборот в компании, тенге QZ"],
    ["cell_phone_of_the_first_head", "Сотовый телефон первого руководителя QZ"],
    ["check_number", "№ чека QZ"],
    ["check_s_file", "Файл чека QZ"],
    ["choose_a_bank", "Выберите банк QZ"],
    ["choose_the_indicator", "Выберите показатель QZ"],
    ["choose_the_period", "Выберите период QZ"],
    ["COMMITMENTS", "Обязательства QZ"],
    ["company_name", "Имя компании QZ"],
    ["company_type", "Тип компании QZ"],
    ["confirm", "Подтвердить QZ"],
    ["confirm?", "Подтвердить QZ"],
    ["confirm_payment", "Подтвердить оплату QZ"],
    ["confirmation_history", "История подтверждений QZ"],
    ["confirmed", "Подтверждено QZ"],
    ["CREATE", "Создана QZ"],
    ["create_request", "Создать заявку QZ"],
    ["credit_sum", "Сумма кредита QZ"],
    ["criteria_signs_assessment_of_group_and_intragroup_indicators", "Критерии/признаки/оценки групповых и внутригрупповых показателей QZ"],
    ["city_of_residence", "Город проживания QZ"],

    //Dd
    ["data_sending_error", "Ошибка отправки данных QZ"],
    ["delete", "Удалить QZ"],
    ["deleted", "Удалено QZ"],
    ["detailed_information", "Подробная информация QZ"],
    ["details", "Подробнее QZ"],
    ["dictionaries", "Справочники QZ"],

    //Ee
    ["edit", "Редактировать QZ"],
    ["edit_dictionary", "Редактировать справочник QZ"],
    ["edit_period", "Редактировать период QZ"],
    ["eighted_score", "Взвешененая оценка QZ"],
    ["eights", "Веса QZ"],
    ["email", "Эл. почта QZ"],
    ["email_of_the_first_manager", "Email первого руководителя QZ"],
    ["enter_a_check_number", "Введите номер чека QZ"],
    ["enter_email", "Введите эл. почту QZ"],
    ["enter_initial_data", "Введите вводные данные... QZ"],
    ["enter_payment_amount", "Введите сумму оплаты QZ"],
    ["enter_payment_limit", "Введите лимит оплаты QZ"],
    ["error_try_again_later", "Ошибка, повторите позднее QZ"],
    ["exit", "Выйти QZ"],
    ["EXPENSES", "Расходы QZ"],

    //Ff
    ["field_of_activity", "Сфера деятельности QZ"],
    ["fill_all_fields_correctly", "Заполните правильно все поля QZ"],
    ["fill_out_the_forms", "Заполните формы! QZ"],
    ["fill_out_the_questionnaire", "Заполните опросник QZ"],
    ["fill_the_form", "Заполните анкету QZ"],
    ["financial_condition_of_the_borrower", "Финансовое состояние заёмщика QZ"],
    ["fio", "ФИО QZ"],
    ["first_name", "Имя QZ"],
    ["forget_password?", "Забыли пароль? QZ"],
    ["founder_email", "Email учредителя QZ"],
    ["founder_s_phone", "Сотовый телефон учредителя QZ"],
    ["full_company_name", "Полное наименование компании QZ"],
    ["full_name_of_the_first_head", "ФИО первого руководителя QZ"],
    ["full_name_of_the_founder_s", "ФИО Учредителя/ей QZ"],

    //Gg
    //Hh
    ["home", "Главная QZ"],

    //Ii
    ["IE", "Индивидуальный предприниматель QZ"],
    ["iin_of_the_first_head", "ИИН первого руководителя QZ"],
    ["iin_of_the_founder", "ИИН учредителя QZ"],
    ["INCOME", "Доходы QZ"],
    ["incoming_requests_for_confirmation_of_payment", "Входящие заявки на подтверждение оплаты QZ"],
    ["incoming_requests_for_processing", "Входящие заявки на обработку QZ"],
    ["indicator_name", "Наименование показателя QZ"],
    ["indicators", "Показатели QZ"],
    ["investment_purpose", "Цель инвестирования QZ"],

    //Jj
    //Kk
    //Ll
    ["last_name", "Фамилия QZ"],
    ["letter_was_sent_to_email", "Письмо было отправлено на почтовый ящик QZ"],
    ["limit", "Лимит QZ"],
    ["LLP", "Юридическое лицо QZ"],
    ["login", "Вход QZ"],

    //Mm
    ["mandatory_field", "Обязательное поле QZ"],
    ["middle_name", "Отчество QZ"],
    ["models", "Модели QZ"],
    ["models_settings", "Настройка моделей QZ"],
    ["monthly", "Месячно QZ"],
    ["my_requests", "Мои заявки QZ"],

    //Nn
    ["new_password_set", "Новоый пароль сохранен QZ"],
    ["next", "Далее QZ"],
    ["no", "нет QZ"],
    ["no_confirmed", "Не подтверждено QZ"],
    ["nomer_p_p", "№ п/п QZ"],

    //Oo
    ["other", "Другое QZ"],
    ["own_cabinet", "Личный кабинет QZ"],

    //Pp
    ["PASSIVE", "Пассив QZ"],
    ["password", "Пароль QZ"],
    ["password_recovery", "Восстановления пароля QZ"],
    ["password_should_be_not_less_than_8_symbols_and_contains_a_digit_and_letter_in_upper_and_lower_case", "Пароль должен быть не менее 8 символов, содержать цифру, букву в нижнем, в верхнем регистре QZ"],
    ["payment_date", "Дата оплаты QZ"],
    ["payment_limit", "Лимит оплаты QZ"],
    ["payments_processing", "Обработка оплат QZ"],
    ["per_cent", "Процентная ставка QZ"],
    ["period_year", "Срок лет QZ"],
    ["photo", "Фотография QZ"],
    ["planned_amount_of_your_investment_in_one_project", "Планируемая сумма Ваших инвестиций в один проект (в тенге) QZ"],
    ["planned_investment_period", "Планируемый срок инвестирования QZ"],
    ["predefault_rating", "Преддефолтный рейтинг QZ"],
    ["PROCESS", "В обработке QZ"],
    ["processing_completed", "Обработка завершена! QZ"],
    ["profile", "Профиль QZ"],
    ["PROFIT", "Прибыль и убытки QZ"],
    ["proof_of_payment", "Подтверждение оплаты QZ"],
    ["providing_a_personal_guarantee_to_the_founder_s", "Предоставление личной гарантии учредителя/ей QZ"],
    ["PURCHASE", "Приобретение основных средств QZ"],

    //Qq
    ["quality_rating", "Качественный рейтинг QZ"],
    ["quantity_rating", "Количественный рейтинг QZ"],
    ["quarterly", "Квартально QZ"],
    ["questionnaire", "Опросник QZ"],

    //Rr
    ["reduced_period", "Льготный период QZ"],
    ["register_successfully", "Регистрация прошла успешно! QZ"],
    ["registration", "Регистрация QZ"],
    ["repeat_password", "Повторите пароль QZ"],
    ["REPLENISHMENT", "Пополнение оборотных средств QZ"],
    ["request_sent", "Заявка отправлена! QZ"],
    ["requested_amount_tenge", "Запрашиваемая сумма, тенге QZ"],
    ["RequestProcessingChesser", "Модель Чессера QZ"],
    ["RequestProcessingIgea", "Модель ИГЭА QZ"],
    ["RequestProcessingTaffler", "Модель Таффлера QZ"],
    ["requests", "Заявки QZ"],
    ["requests_processing", "Обработка заявок QZ"],
    ["required_reference_books_are_missing", "Отсутствуют необходимые справочники QZ"],
    ["restore", "Восстановить QZ"],
    ["risk_assessment_class", "Класс оценки риска QZ"],

    //Ss
    ["save", "Сохранить QZ"],
    ["save_new_password", "Сохранить новый пароль QZ"],
    ["send", "Отправить QZ"],
    ["send_a_request", "Отправить заявку! QZ"],
    ["settings", "Настройки QZ"],
    ["sign_in", "Войти QZ"],
    ["sing_in_like_admin", "Войти как администратор (менеджер) QZ"],
    ["sing_in_like_investor", "Войти как инвестор QZ"],
    ["sing_in_like_startup", "Войти как стартапер QZ"],
    ["status", "Статус QZ"],
    ["success", "Успешно QZ"],

    //Tt
    ["telephone_number", "Номер телефона QZ"],
    ["the_list_of_applications_is_empty", "Список заявок пуст... QZ"],
    ["the_purpose_of_attracting", "Цель привлечения QZ"],
    ["type", "Тип QZ"],

    //Uu
    //Vv
    ["value_of_indicators", "Значение показателей QZ"],
    ["view", "Просмотр QZ"],

    //Ww
    ["what_is_the_accounting", "В чем ведется учет QZ"],
    ["why_did_you_decide_to_invest_in_ABC_deals?", "Почему Вы решили инвестировать в сделки ABC? QZ"],
    ["work_experience_of_the_first_head_months", "Стаж работы первого руководителя, месяцев QZ"],
    ["work_experience_of_the_founder_s__max_months", "Стаж работы учредителя/лей, месяцев (макс) QZ"],
    ["wrong_login_or_password", "Неверный логин или пароль QZ"],

    //Xx
    //Yy
    ["yearly", "По годам QZ"],
    ["yes", "да QZ"],

    //Zz
])
