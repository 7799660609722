import store from '@/store'

export default (error) => {
  store.dispatch("setError", true)
  if(!error) {
    console.error(Error("No response received from remote server!"));
    return undefined;
  }
  console.error(Error(error.status + error.statusText));
  // store.dispatch("setError", {type: 'error', message: error.statusText} )
  if (error.status === 401) {
    store.dispatch("logout", false);
  } else {
    return error;
  }
}
