export default function dateFormat(str) {
    if(str) {
        let ms = new Date(str).getTime()
        if (ms) {
            ms = new Date(ms)
            return (ms.getDate() < 10 ? '0' + ms.getDate() : ms.getDate())
                + '.'
                + (ms.getMonth() + 1 < 10 ? '0' + (ms.getMonth() + 1) : ms.getMonth() + 1)
                + '.'
                + ms.getFullYear()
        } else {
            return ''
        }
    }
    else {
        return '-'
    }
}
