<template>
  <div>
    <v-app>
<!--      <v-app-bar app color="#FFF">-->
<!--        <v-app-bar-nav-icon style="visibility: hidden"></v-app-bar-nav-icon>-->
<!--        <the-brand></the-brand>-->
<!--      </v-app-bar>-->
      <v-main class="toolbar-no-padding">
        <router-view />
      </v-main>
<!--      <the-footer/>-->
    </v-app>
  </div>
</template>


<script>
export default {
  name: "error-layout",
  // components: {TheFooter, TheBrand},
}
</script>


