import Cookies from 'js-cookie'

const investorMiddleware = async ({ /* to, from to,*/ redirect }) => {
    let user = undefined
    if(Cookies.get('user')) user = JSON.parse(Cookies.get('user'))

    if(!user){
        redirect({
            name: "error",
        });
    }
    else if(user.logAs !== 'investor' || !user.investor){
        redirect({
            name: "error",
        });
    }
};

export default investorMiddleware;