import Cookies from 'js-cookie'

const adminMiddleware = async ({ /* to, from to,*/ redirect }) => {
    let user = undefined
    if(Cookies.get('user')) user = JSON.parse(Cookies.get('user'))

    if(!user){
        redirect({
            name: "error",
        });
    }
    else if(user.logAs !== 'admin' || !user.admin){
        redirect({
            name: "error",
        });
    }
};

export default adminMiddleware;