export default function searchTree(element, matchingUid){
    if(element.uid === matchingUid){
        return element;
    }else if (element.items != null){
        var i;
        var result = null;
        for(i=0; result == null && i < element.items.length; i++){
            result = searchTree(element.items[i], matchingUid);
        }
        return result;
    }
    return null;
}
