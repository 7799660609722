<template>
  <v-navigation-drawer
      class="drawer elevation-2"
      permanent
      expand-on-hover
      absolute
      style="z-index: 4"
  >
    <v-list
        nav
        dense
        class="pa-0"
    >
      <v-list-item-group
          v-model="index"
      >
        <div v-for="(item, i) in items"
             :key="i"
             :style="isActive(i) ? 'background-color: #E8F5E9' : ''"
             @click="click">
          <v-list-item class="pl-4">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $localize(item.title) }}</v-list-item-title>
          </v-list-item>
        </div>

      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: "the-nav-drawer",
  data : () => ({
    index: undefined,
    selectedItem: undefined,

    itemsStartup: [
      {title: 'requests',icon: 'mdi-send-clock-outline', path: '/startup/requests/my'},
      {title: 'dictionaries',icon: 'mdi-book-open-outline', path: '/startup/dictionaries'},
      {title: 'calculator',icon: 'mdi-calculator', path: '/startup/calculator'},
    ],
    itemsInvestor: [
      {title: 'questionnaire',icon: 'mdi-book-account-outline', path: '/investor/questionnaire'},
      {title: 'Предложения',icon: 'mdi-book-search-outline', path: '/investor/test'},
      {title: 'calculator',icon: 'mdi-calculator', path: '/investor/calculator'},
    ],
    itemsAdmin: [
      {title: 'payments_processing',icon: 'mdi-credit-card-outline', path: '/admin/processing-payments'},
      {title: 'requests_processing',icon: 'mdi-send-clock-outline', path: '/admin/processing-requests'},
      {title: 'dictionaries',icon: 'mdi-book-open-outline', path: '/admin/dictionaries'},
      {title: 'settings',icon: 'mdi-cog', path: '/admin/settings'},
    ],
    itemsDefault: [],

  }),

  computed:{
    items(){
      if(this.$cookies.user){
        const user = JSON.parse(decodeURIComponent(this.$cookies.user))
        switch (user.logAs){
          case 'startup' : return this.itemsStartup
          case 'investor' : return this.itemsInvestor
          case 'admin' : return this.itemsAdmin
          default : return this.itemsDefault
        }
      }else return this.itemsDefault
    }
  },

  watch:{
    '$router.currentRoute.path'(path){
      if(path && !this.items.find(el => path === el.path)) {
        this.index = undefined
        this.selectedItem = undefined
      }
      else {
        let idx = this.items.indexOf(this.items.find(el => path === el.path))
        this.index = idx
        this.selectedItem = idx
      }
    },

    index(value){
      if(value || value === 0) this.selectedItem = value
    }
  },

  mounted(){
    this.selectedItem = this.items.indexOf(this.items.find(el => this.$router.currentRoute.path === el.path))
  },

  methods: {
    click() {
      if(!this.items[this.selectedItem]){
        console.log('i am here')
      }
      else if ( this.$router.currentRoute.path !== this.items[this.selectedItem].path) {
        this.$router.push(this.items[this.selectedItem].path).catch(() => {
        });
      }
    },
    isActive(index){
      return index === this.selectedItem;
    }
  }
}
</script>

<style scoped lang="scss">
.drawer{
  margin-top: 64px;
}
</style>
